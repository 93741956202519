@font-face {
  font-family: 'eighties-comeback';
  src: url('../../public/fonts/EightiesComeback_VAR-VF-SECONDARY.ttf');
 }

.core__paragraph {
  font-size: 20px;
}

.core__paragraph__inverse {
  font-size: 20px;
  color: white;
  font-weight: lighter;
}

.core__heading {
  font-family: 'eighties-comeback';
  font-size: 55px;
  line-height: 4rem;
}

.core__heading__inverse {
  font-family: 'eighties-comeback';
  font-size: 55px;
  color: white;
  font-weight: lighter;
  line-height: 4rem;
}

.core__plain__heading {
  font-size: 50px;
  font-weight: bold;
}

.core__plain__heading__inverse {
  font-size: 50px;
  color: white;
  font-weight: bold;
}

@media only screen and (max-width: 1600px) {

  .core__paragraph {
    font-size: 16px;
  }

  .core__paragraph__inverse {
    font-size: 16px;
  }

  .core__heading {
    line-height: 3.5rem;
  }

  .core__heading__inverse {
    line-height: 3.5rem;
  }

  .core__plain__heading {
    font-size: 40px;
  }

  .core__plain__heading__inverse {
    font-size: 40px;
  }
}

@media only screen and (max-width: 1024px) { 

  .core__paragraph {
    font-size: 14px;
  }

  .core__paragraph__inverse {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) { 

  .core__paragraph {
    font-size: 16px;
  }

  .core__paragraph__inverse {
    font-size: 16px;
  }

  .core__heading {
    font-size: 35px;
    line-height: 2.5rem;
  }

  .core__heading__inverse {
    font-size: 35px;
    line-height: 2.5rem;
  }
}

@media only screen and (max-width: 480px) {

  .core__heading {
    font-size: 26px;
  }

  .core__heading__inverse {
    font-size: 26px;
  }

  .core__plain__heading {
    font-size: 26px;
  }

  .core__plain__heading__inverse {
    font-size: 26px;
  }
}
