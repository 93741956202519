@import 'components/core/core.module.scss';

.marketplace__container {
    display: grid;
    grid-template-columns: 10% 3fr 4fr;
    grid-template-rows: 50px 1fr 40px;
    justify-content: right;
}
  
.marketplace__image {
    background-image: url('https://cdn.catalist.co.nz/v2/Catalist_Home_Tree_SingleImage_Cropped.png');
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute; 
    height: 185%; 
    top: -85%;
    width: 100%;
}

.marketplace__image__container {
    display: flex;
    justify-content: center;
    grid-column-start:3;
    grid-row-start: 2;
    position: relative;
    text-align: center;
}

.marketplace__banner_container {
    grid-column-start: 2;
    grid-row-start: 2;
    padding-right: 10%;
}
  
@media only screen and (max-width: 1024px) { 
    .marketplace__container {
        grid-template-columns: 10% 3fr 0.5fr;
        grid-template-rows: 10% 2fr 1.5fr 10%;
    }

    .marketplace__image {
        height: 110%; 
        top: -50px;
        background-image: url('https://cdn.catalist.co.nz/v2/Catalist_Home_Tree_SingleImage.png');
        background-position: center;
    }

    .marketplace__image__container {
        grid-row-start: 2;
        grid-column-start:2;
    }

    .marketplace__banner_container {
        padding-top:40px;
        grid-column-start: 2;
        grid-row-start:3;
    }
}
  
@media only screen and (max-width: 768px) { 
    .marketplace__container {
        grid-template-rows: 5% 2fr 2fr 5%;
    }

    .marketplace__banner_container {
        padding-top: 10px;
    }
}

@media only screen and (max-width: 480px) { 
    .marketplace__container {
        grid-template-columns: 15% 75% 10%;
        grid-template-rows: 1% 2fr 2fr 10%;
    }

    .marketplace__image {
        height: 110%; 
        top: -20px;
        background-position: center;

    }
}