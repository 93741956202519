@import 'components/core/core.module.scss';

.signup__heading {
  padding-top: 7% !important;
}

.signup__title {
  padding-top: 10% !important;
  padding-bottom: 10% !important;
}

.signup__disclaimer {
  padding-top: 3%;
}

.signup__button__container {
  padding-top: 3%;
}

.signup__accountlink_container {
  padding-top: 5%;
}

.signup__container {
  padding-left: 5%;
  padding-right: 5%;
  background-color: white;
}

.signup__icon__container {
  float: right;
}

.signup__icon {
  color: #0d2d50;
  cursor: pointer;
}

.signup__paragraph {
  font-size: 20px;
}

@media screen and (max-width: 600px) { 
  .signup__paragraph {
    font-size: 20px;
  }
}
