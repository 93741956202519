
.footer__container {
  display: grid;
  min-height: 345px;
  grid-template-columns: 1fr 1fr 1fr 3fr;
  background-color: #0d2d50;
  padding-left: 10%;
  padding-right: 0%;
  padding-top: 5%;
  border-top: 1px solid rgba(255,255,255, 0.1);
}

.footer__catalist__white-logo {
  padding-right: 20%;
  min-width: 300px;
  float: right
}

.footer__social__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100px auto;
  justify-content: center;
  flex-direction: column;
}

.footer__socialMedia__link {
  align-items: top;
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;
}

.footer__legal {
  font-family: 'Sofia-Pro';
  color: white;
  padding-top: 20px;
  font-weight: 200;
}

.site__links__container {
  display: flex;
  justify-content: top;
  align-items: flex-start;
  flex-direction: column;
}

.site__links__text {
  font-family: 'Sofia-Pro';
  color: white;
  padding-bottom: 12px;
  font-weight: 200;
}

.site__links__text:hover {
  color: #0156D0;
}

@media only screen and (max-width: 1300px) {

  .footer__catalist__white-logo {
    min-width: 200px;
  }
  
}

@media only screen and (max-width: 1024px) {

  .site__links__container {
    align-items: left;
  }

  .footer__container {
    grid-template-columns: 1fr 1fr;
    padding: 10%
  }

  .footer__social__container {
    grid-row-start: 1;
    grid-column-start: 2 span;
    padding-bottom: 40px;
    grid-template-columns: 1fr;
  }
  
  .footer__socialMedia__link {
    grid-row-start: 2;
  }
  
  .footer__catalist__white-logo__container {
    grid-row-start: 1;
    padding-bottom: 7%;
  }
  
  .footer__catalist__white-logo {
    padding-right: 20%;
    min-width: 300px;
    float: left
  }

  .footer__copyright__container {
    display: none;
  }
}

@media only screen and (max-width: 768px) {

  .footer__catalist__white-logo {
    min-width: 250px !important;
  }
    
  .footer__catalist__white-logo__container {
    padding-bottom: 10%;
  }

}

@media only screen and (max-width: 480px) {
  
  .footer__catalist__white-logo__container {
    padding-bottom: 15%;
  }

  .footer__catalist__white-logo {
    min-width: 200px !important;
    float: left
  }
}