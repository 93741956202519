@import '../../styles/global/variables.scss';


.public__content__container {
    // hide white space below ele
    margin-bottom: -1px;
    text-align: center;
    background-color: $color-blue-dark;
    padding-top: 10%;
    clip-path: polygon(0% 20%, 100% 0%, 100% 100%, 0% 100%);
    padding-left: 10%;
    padding-right: 10%;
}

.public__content__spacer {
    padding-top: 10%;
}

.home_page_feature_content__detail {
    padding-top: 7%;
    padding-left: 10%;
    margin-right: 0%;
    text-align: left;
    // set height so children can use height
    height: 100%;
    position: relative;
}

.home_page_feature_container_in_carousel{
    margin-right: 5%;
    margin-left: 5%;
    background-color: $color-white;
    aspect-ratio: 2/4;
    padding-top: 15%;
    clip-path: polygon(0% 7%, 100% 0%, 100% 100%, 0% 100%);
    position: relative;
}

.public__content__container .slide{
    width:33.3%;
}

.carousel__header {
    font-size: 56px;
    margin-bottom: 6%;
    font-family: 'Sofia-Pro';
    color: $color-white;
    padding: 0;
}

.carousel-bottom {
    position: absolute;
    bottom: 0px;
    width: 90%;
    text-align: left;
}

.carousel-bottom__grid__row {
    padding: 0px !important;
    min-height: 33% !important;
}

.carousel-bottom__openstatus {
    padding-top: 5% !important;
}

.carousel-bottom__label__blue {
    background-color: $color-blue-primary;
    clip-path: polygon(0% 22%, 100% 15%, 100% 100%, 0% 100%);
}

.carousel-bottom__label__orange {
    background-color: $color-orange;
    clip-path: polygon(0% 22%, 100% 15%, 100% 100%, 0% 100%);
}

.carousel-bottom__label {
    background-color: $color-blue-dark;
    clip-path: polygon(0% 22%, 100% 15%, 100% 100%, 0% 100%);
}

.carousel-bottom__label__text {
    margin-top: 40px !important;
    transform: rotate(90deg);
    text-transform: uppercase;
    color: $color-white;
    font-size: 20px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.carousel-bottom__status {
    color: #005bcb;
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 700;
}

.fund-type {
    color: $color-blue-dark;
    padding-top: 7%;
    text-transform: uppercase;
    margin-bottom: 0px;
    font-size: 18px;
}

.share-type__container {
    height: 12% !important;
}

.share-type {
    color: grey;
    padding-top: 4%;
    margin-bottom: 0px;
    font-size: 16px;
}

.business-name__container {
    height: 20% !important;
}

.business-name {
    padding-top: 7%;
    font-size: 18px;
    font-weight: 700;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}

.carousel-bottom__field {
    color: grey;
    font-size: 18px;
    text-transform: uppercase;
}

.carousel-bottom__value {
    font-size: 18px;
    font-weight: 700;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}

.no-label__container {
    height: 140px;
    padding-top: 5%;
}

.total-value__container {
    height: 40%;
}

.open-to__container{
    height: 40%;
}

.open-now__container {
    height: 10%;
}




@media only screen and (max-width: 1800px) { 
    .public__content__container {
        padding-left: 0%;
        padding-right: 0%;
    }
}  

@media only screen and (max-width: 1500px) { 
    
    .hero__image {
      z-index: 800;
    }

    .fund-type {
        font-size: 14px;
    }
    
    .share-type {
        font-size: 14px;
    }
    
    .business-name {
        font-size: 16px;
        padding-top: 0%;
    }
    
    .carousel-bottom__field {
        font-size: 16px;
    }
    
    .carousel-bottom__value {
        font-size: 16px;
    }    
    .carousel-bottom__label__text {
        margin-top: 40px !important;
        font-size: 18px;
    }

    .carousel-bottom__status {
        font-size: 20px;
    }
}  

@media only screen and (max-width: 1300px) { 
    .carousel-bottom {
        position: relative;
        width: 90%;
        text-align: left;
    }
    
    .fund-type {
        font-size: 10px;
    }
    
    .share-type {
        font-size: 12px;
    }
    
    .business-name {
        padding-top: 5%;
        font-size: 14px;
    }
    
    .carousel-bottom__field {
        font-size: 10px;
    }
    
    .carousel-bottom__value {
        font-size: 14px;
    }    

    .carousel-bottom__status {
        padding-top: 5%;
        font-size: 16px;
    }

    .carousel-bottom__label__text {
        margin-top: 30px !important;
        font-size: 20px;
    }

    .carousel-bottom__opento {
        padding: 10% 0px 0px 0px !important;
    }
    
    .carousel-bottom__openstatus {
        padding-top: 7% !important;
    }
}  

@media only screen and (max-width: 1024px) {
    
    .carousel__header {
        font-size: 36px;
    }

    .fund-type {
        font-size: 8px;
    }
    
    .share-type {
        font-size: 8px;
    }
    
    .business-name {
        font-size: 10px;
    }
    
    .carousel-bottom__field {
        font-size: 8px;
    }
    
    .carousel-bottom__value {
        font-size: 10px;
    }

    .carousel-bottom__status {
        font-size: 12px;
    }
    
    .no-label__container {
        height: 110px;
        padding-top: 2%;
    }

}

@media only screen and (max-width: 768px)

{

    .public__content__container {
        clip-path: polygon(0% 10%, 100% 0%, 100% 100%, 0% 100%);

    }

    .public__content__container .slide{
        width:50%;
    }

    .carousel-bottom__opento {
        padding: 15% 0px 0px 0px !important;
    }

    .carousel-bottom__openstatus {
        padding: 10% 0 30% 0 !important;
    }
    
    .fund-type {
        display: none !important;
    }
    
    .share-type {
        display: none !important;
    }
    
    .business-name {
        padding-top: 5% !important;
        font-size: 30px;
    }
    
    .carousel-bottom__field {
        font-size: 20px;
    }
    
    .carousel-bottom__value {
        font-size: 25px;
    }

    .carousel-bottom__status {
        font-size: 30px;
    }

    .share-type__container {
        display: none;
    }
}
@media only screen and (max-width: 768px)
 {
    .business-name {
        padding-top: 0%;

        font-size: 8px;
    }
    
    .carousel-bottom__field {
        font-size: 8px;
    }
    
    .carousel-bottom__value {
        font-size: 10px;
    }

    .carousel-bottom__status {
        font-size: 10px;
    }
    .carousel__header {
        font-size: 26px;
    }

    .public__content__container {
        clip-path: polygon(0% 10%, 100% 0%, 100% 100%, 0% 100%);
    }

    .public__content__container .slide{
        width:50%;
    }

    .carousel-bottom__opento {
        padding: 10% 0px 0px 0px !important;
    }

    .carousel-bottom__openstatus {
        padding: 7% 0 25% 0 !important;
    }
    
    .fund-type {
        display: none !important;
    }
    
    .share-type {
        display: none !important;
    }

    .share-type__container {
        display: none;
    }

    
}
@media only screen and (max-width: 580px) {
    
    .open-now__container {
        display: none;
    }
    
    .no-label__container {
        height: 85px;
    }
}

@media only screen and (max-width: 480px) {
    .open-now__container {
        display: inline-block;
    }
    
    .no-label__container {
        height: 175px;
        padding-top: 35%;
    }

    .business-name {
        padding-top: 15% !important;
        font-size: 12px;
    }
    
    .carousel-bottom__field {
        font-size: 10px;
    }
    
    .carousel-bottom__value {
        font-size: 12px;
    }

    .carousel-bottom__status {
        font-size: 15px;
    }
    .carousel__header {
        font-size: 26px;
    }

    .home_page_feature_content__detail {
        margin-left: 8%;
        margin-right: 8%;
    }

    .public__content__container {
        padding-left: 15px;
        padding-bottom: 15px;
    }
}

.public__content__container .slide{
    width:50%;
}