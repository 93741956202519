@import 'components/core/core.module.scss';

.hero__container {
  display: grid;
  grid-template-columns: 0.5fr 4fr 3fr 0.5fr;
  grid-template-rows: 150px 1fr 40px;
  justify-content: center;
}

.hero__image {
  background-image: url('https://cdn.catalist.co.nz/v2/Catalist_Home_Hero_SingleImage.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute; 
  height: 185%; 
  top: -32%;
  width: 100%;
}

.hero__image__container {
  display: flex;
  justify-content: center;
  grid-column-start:2;
  grid-row-start: 2;
  position: relative;
  text-align: center;
}

.hero__header {
  font-size: 34px;
  font-family: 'Sofia-Pro';
  padding: 0;
  color: #0D2D50;
  font-weight: 700;
}

.hero__banner__subtitle {
  padding-top: 8px;
  padding-bottom: 60px;
  font-size: 20px;
}

.hero__banner_container {
  grid-column-start: 3;
  grid-row-start: 2;
  padding-left: 5%;
  padding-top: 5%;
  padding-right: 15%;
}

@media only screen and (min-width: 1500px) { 
  .hero__image {
    z-index: 800;
  }
}

@media only screen and (min-width: 1920px) {  
  .hero__header {
    font-size: 36px;
  }

  .hero__image {
    min-height: 5vh;
  }
}

@media only screen and (max-width: 1024px) { 
  .hero__container {
    grid-template-columns: 0.5fr 3fr 0.5fr;
    grid-template-rows: 150px 2fr 2fr 50px;
  }

  .hero__image {
    top: -15%;
    height: 110%; 
    z-index: -1;
  }
  
  .hero__image__container {
    grid-row-start: 2;
    z-index: -1;
  }

  .hero__banner_container {
    grid-column-start: 2;
    grid-row-start:3;

  }
}

@media only screen and (max-width: 768px) { 
  
  .hero__header {
    font-size: 26px;
  }
  
  .hero__container {
    grid-template-rows: 125px 2fr 3fr 50px;
  }
}