@import 'components/core/core.module.scss';

.appbar__bars {
  float: right;
  cursor:pointer;
}

.appbar__container__mobile {
  display: none;
}

.appbar__container__mobile__inverted {
  display: none;
}

.appbar__mainlinks__container {
  background-color: white;
  padding-bottom: 2rem;
}

.appbar__smalllinks__container {
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.appbar__sidebar__footer {
  position: absolute;
  padding-left: 1rem;
  padding-top: 3vh;
  height: 10vh;
  width: 100%;
  background-color: #0d2d50;
  bottom: 0px;
  align-items: top;
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.appbar__sidebar {
  width: 90vw !important;
  overflow: hidden;
}

.appbar__sidebar__defaultopts {
  max-width: 350px;
  background-color: #f6f6f6 !important;
}

.appbar__sidebar__signupopts {
  max-width: 350px;
}

.appbar__sidebar__image {
  float: left;
  max-width: 150px !important;
  min-width: 150px;
}

.appbar__sidebar__icon__container {
  float: right;
}

.appbar__sidebar__icon {
  color: #0d2d50;
}

.appbar__sidebar__join__btn {
  padding-left: 1rem;
  padding-top: 2rem;
  width: 90% !important;
}

.appbar__sidebar__login__btn {
  padding-left: 1rem;
  padding-top: 0.5rem;
  width: 90% !important;
}

.appbar__desktop__logo {
  min-width: 150px;
}

.appbar__pusher {
  height: auto;
  width: 100%;
}

.appbar__pusher__expanded {
  height: 100vh !important;
}

// remove line between menu items
.appbar__menu__item__container::before {
  height: 0px !important;
}

.appbar__menu__item__container {
  text-align: left !important;
  width: 100% !important;
  height:2.75rem;
}

.appbar__menu__item {
  font-weight: 400;
  font-size:x-large;
}

.appbar__menu__item__active {
  font-weight: 800;
  font-size:x-large;
  color: #0156D0;
}

.appbar__menu__header {
  padding-top: 1rem !important;
  padding-bottom: 3rem !important;
}

.appbar__desktop {
  display: contents;
}

.appbar__desktop-hover:hover {
  color: #0156D0;
}

.appbar__desktop-hover-inverse:hover {
  color: #ee8a52
}

.appbar__button {
  font-weight: lighter;
}

@media only screen and (max-width: 800px) { 
  .appbar__container__mobile {
    display: flex;
    padding-top: 16px;
    padding-right: 20px;
    padding-left: 18px;
    min-width: 100vw;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding-bottom: 18px;
  }
  .appbar__container__mobile__inverted {
    display: flex;
    padding-top: 16px;
    padding-right: 20px;
    padding-left: 18px;
    min-width: 100vw;
    justify-content: space-between;
    align-items: center;
    background-color: #0d2d50;
    padding-bottom: 18px;
  }
  .appbar__desktop {
    display: none;
  }
}



//Animations
.shimmerEffect {
  position: relative; 
  display: inline-block; 
  color: #0156D0; /* base text color (blue) */
  overflow: none;
}

.shimmerEffect::after {
  content: '';
  position: absolute;
  top: 0; 
  left: -150%;
  width: 50%; 
  height: 100%;
  /* A gradient that goes from transparent -> white -> transparent 
     to simulate a sweeping highlight */
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0)
  );
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    left: -150%;
  }
  50% {
    left: 150%;
  }
  100% {
    left: 150%;
  }
}