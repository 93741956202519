@import '../../styles/global/variables.scss';


.homestats__content__container {
    background-color: $color-blue-dark;
    padding: 4.2% 10% 2.1% 10%;
}

.homestats__polygon {
    aspect-ratio: 7/1;
    background-color: $color-blue-dark;
    clip-path: polygon(100% 2%, 100% 0%, 0% 0%, 0% 100%);
    // cover white line gap
    margin-top: -2px
}

.stats__header {
    font-size: 56px;
    margin-bottom: 4%;
    font-family: 'Sofia-Pro';
    color: $color-white;
    padding: 0;
    text-align: center;
}

.stats__grid {
    text-align: left;
    
    h1{
        color: $color-orange;
        font-size: 50px;
        margin: 0px;
    }

    p{
        text-align: centre;
        color: $color-white;
    }
}

@media only screen and (max-width: 1300px) { 
    
    .stats__grid {       
        h1{
            font-size: 40px;
        }
    
        p{
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}  

@media only screen and (max-width: 1024px) {
    
    .stats__header {
        font-size: 36px;
    }
    
    .stats__grid {
        padding-left: 0px !important;
        padding-right: 0px !important;
        
        h1{
            font-size: 30px;
            margin: 0px;
        }
    }
}

@media only screen and (max-width: 768px) {

    .homestats__content__container {
        padding: 60px 0% 2% 0%;
    }

    .stats__grid {
        text-align: center;

        h1{
            font-size: 45x;
        }
    }
}

@media only screen and (max-width: 480px) {
    
    .stats__header {
        font-size: 26px;
    }

}