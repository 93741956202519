@import 'components/core/core.module.scss';
@import '../../styles/global/variables.scss';


.headline__background {
  background-image: url('https://cdn.catalist.co.nz/v2/Catalist_Background2.png');
  background-size:cover;
}

.headline__polygon {
  aspect-ratio: 7/1;
  // cover the 1px gap between path and background
  clip-path: polygon(100% -1px, 0% 100%, 0% -1px);
  background-color: $color-white;
}

.headline__container {
  display: grid;
  grid-template-columns: 10% 4fr 3fr 10%;
  grid-template-rows: 10px 1fr 120px;
  justify-content: center;
}

.headline__image {
  background-image: url('https://cdn.catalist.co.nz/v2/Catalist_Home_CatalyseFinSuccess_option1.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
}

.headline__image__container {
  display: flex;
  justify-content: center;
  grid-column-start:2;
  grid-row-start: 2;
  position: relative;
  text-align: center;
}

.headline__banner_container {
  grid-column-start: 3;
  grid-row-start: 2;
  padding-left: 5%;
  padding-top: 15%;
}


@media only screen and (max-width: 1024px) { 
  .headline__container {
    grid-template-columns: 10% 3fr 10%;
    grid-template-rows: 10px 2fr 2fr 50px;
  }

  .headline__image {
    height: 110%; 
    top: 0%;
  }
  
  .headline__image__container {
    grid-row-start: 2;
  }

  .headline__banner_container {
    grid-column-start: 2;
    grid-row-start:3;
    padding-left: 0%;
    padding-top: 25%;
  }
}

@media only screen and (max-width: 768px) { 
  .headline__container {
    grid-template-rows: 0.1fr 2fr 2fr 0.5fr;
  }
}

@media only screen and (max-width: 580px) { 
  .headline__container {
    grid-template-rows: 0.1fr 1fr 2fr 0.5fr;
  }
}